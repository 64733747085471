.nav-menu-container {
  width: 100vw;
}
.menu-flex {
  // position: relative;
}
.boxcompany {
  transform: translate(-33.5rem, -1px);
}
.airborderInfo {
  width: 2px;
  background-color: #e5e5e5;
  height: 2rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.air {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: #343c5b;
  img {
    margin-left: 10px;
  }
}
.big-box1 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  top: 95px;
  right: calc(8vw + 400px);
  z-index: 17;
}

.big-box2 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  top: 95px;
  right: calc(8vw + 300px);
  z-index: 17;
}
.thebox {
  transform: translate(0rem, 0px);
}
.theproduct {
  transform: translate(-16rem, -0px);
}
.theAffliate {
  transform: translate(-26rem, -0px);
}

.Thecommunicty {
  transform: translate(-26rem, -0px);
}
.boxcompany {
  transform: translate(-33.5rem, -0px);
}
.big-box3 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: absolute;

  top: 95px;
  z-index: 17;
  display: flex;
  justify-content: space-between;
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;

  position: absolute;
  /* right: 9vw; */
  left: 0;
  top: 79px;
  z-index: 17;
  width: max-content;

  z-index: 17;
  width: 100%;
  max-width: 1498px;
  left: 50%;
  transform: translate(-50%, 0);
  justify-content: flex-start;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px 0px 25px 25px;
  border-top: 0px solid;
}

.menus-left {
  width: fit-content;
  padding-top: 35px;
  padding-bottom: 35px;
}

.menus-right {
  width: fit-content;
  margin-left: 40px;
  border-left: 1px solid #e7e7e7;
  padding-left: 40px;
  padding-right: 40px;
  border-right: 1px solid #e7e7e7;
  padding-bottom: 35px;
  padding-top: 35px;
}

.big-box4 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  top: 95px;
  right: calc(4vw);
  z-index: 17;
}

.menu-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #182542;
  // 
}

.menu-desc {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #182542;
  // white-space: nowrap;
}

.each-menu {
  cursor: pointer;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  &:hover {
    background-color: #e7e7e7;
    border-radius: 15px;
  }
}

.menus {
  width: fit-content;
  cursor: pointer;
  margin-top: 10px;
  padding: 20px;
  padding-left: 0;
  display: flex;
  &:hover {
    // background-color: #e7e7e7;
    // border-radius: 15px;
    transform: scale(1.1);
  }
}

.menu-img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
}
.nn-maincustom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nn-main {
  position: fixed;
  width: 94%;
  /* margin-left: 9vh; */
  /* margin-right: 9px; */
  max-width: 1498px;
  height: 80px;
  top: 15px;
  width: 94%;
  // padding: 0px 8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 999;
  background-color: white;
  border: 0.5px solid #e5e5e5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.nn-img {
  height: 43.9px;
}
.nn-img1 {
  position: absolute;
  left: 2%;
  &:hover {
    transform: scale(1.1);
    transition: all ease 0.6s;
  }
}

.nn-left {
  cursor: pointer;
  height: 80px;
  display: flex;
  align-items: center;
}

.nn-right {
  display: flex;
  align-items: center;
  height: 80px;
}
.menushow {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 999;
  position: fixed;
  background: transparent;
  height: 80px;
}
.nn-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #343c5b;
  margin-left: 26px;
  position: relative;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: all ease 0.3s;
  }
}

.boderlenght {
  background: linear-gradient(270deg, #81cbd7 0%, #3d4394 100%);
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 24px;
}
.nn-btn-login {
  margin-left: 27px;
  border: 1px solid transparent;
  width: 117px;
  height: 43px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #343c5b;
  background: white;
  opacity: 0.9;
  border-radius: 10px;
  border-radius: 10px;
  font-weight: 800;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  background: linear-gradient(to right, white, white),
    linear-gradient(0deg, #3d4394 30%, #81cbd7 67%);
  background-position: 0 0, 100% 0;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-style: normal;
  font-weight: 600;
}
.end-nav {
  display: flex;
  align-items: center;
}
.nn-btn {
  margin-left: 15px;
  width: 147px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
  opacity: 0.9;
  border-radius: 10px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.nn-btn::after {
  background: white;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: -10;
}

.nn-btn:hover::after {
  left: 120%;
  transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 50;
}

//   .nn-box-main{
//     height: 200px;
//     width: 300px;
//     border-radius: 20px;
//     background-color: black;
//   }

.socialmedia {
  display: flex;
  margin-top: 1.7rem;
  align-items: center;
  .box {
    width: 87.73px;
    height: 87.73px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 15px;
    &:hover {
      transform: scale(1.1);
    }
  }
}
@media only screen and (max-width: 1569.9px) {
  .nn-img1 {
    position: inherit;
    left: 2%;
    margin-right: 11px;
  }
}
@media only screen and (max-width: 1580px) {
  // .big-box3 {

  //     left: -40vh;

  // }

  .nn-left {
    cursor: pointer;
    height: 80px;
    display: flex;
    align-items: center;
    // padding-left: 3%;
  }
}

@media only screen and (max-width: 1000px) {
  .menushow {
    display: none;
  }
}

@media only screen and (max-width: 1700px) {
  .nn-main {
    width: 94%;
  }
}

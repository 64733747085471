.footer-airwallet {
  background: linear-gradient(380deg, #81cbd7 0%, #3d4394 100%);

  width: 100%;
  padding-bottom: 1rem;
  .left-footer {
  }
  .right-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .h5 {
    display: none;
  }
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    /* or 194% */
    margin-top: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;

    margin-bottom: 0;
  }
  h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 35px;
    /* or 159% */
    width: 465px;
    display: flex;
    align-items: center;

    color: #ffffff;
    margin-bottom: 1rem;
  }

  .innerfooter {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.socialmidia {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:33rem;
  img {
    height: 35.6px;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.input-div {
  height: 72px;
  background: #ffffff;
  border-radius: 25px 25px 25px 25px;
  display: flex;
  width: 465px;
  align-items: center;
  padding-left: 2rem;

  input {
    width: 334px;
    border-radius: 25px 0px 0px 25px;
    height: 72px;
    border: none;
    background: #ffffff;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 35px;
    /* or 175% */
    padding-right: 1rem;
    display: flex;
    align-items: center;

    color: #1f204f;

    opacity: 0.25;
  }
  .join {
    width: 119px;
    height: 72px;

    background: #ededed;
    border-radius: 0px 25px 25px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 65px;
    /* or 325% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #343c5b;

    justify-content: center;
  }
}

.border-footer {
  width: 1px;
  height: 139px;

  background: #ffffff;
}

.topFooter {
  background: #f5f5f5;
  padding-top: 4rem;
  padding-bottom: 4rem;
  .labelsecial-media {
    display: flex;
    align-content: center;
    margin-top: 2rem;
  }
  .logo {
    &:hover {
      transform: scale(1.1);
    }
  }
  .firsttopside {
    height: 212px;
  }
  .social-label {
    width: 190px;
    height: 62px;
    margin-right: 15px;
    display: flex;

    align-items: center;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .topfooterSection {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .footertable {
      &:nth-child(2) {
      }
    }
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    /* or 159% */

    display: flex;
    align-items: center;

    color: #343c5b;
    margin-bottom: 1rem;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    /* or 194% */

    display: flex;
    align-items: center;

    color: #343c5b;
    &:hover {
      transform: scale(1.1);
    }
  }
}

@media (max-width: 900px) {
  .footertable {
    margin-bottom: 1.3rem;
    width: 35%;
    &:nth-child(3) {
      width: 10rem;
    }
  }
  .topFooter {
    // display: none;
    .labelsecial-media {
      display: none;
    }
    .topfooterSection {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
    }
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: #343c5b;
      margin-bottom: 1rem;
    }
    .first {
      // display: none !important;
      width: 100% !important;
      height: 6rem;
    }
  }
  .border-footer {
    width: 1px;
    height: 139px;
    background: #ffffff;
    display: none;
  }
  .input-div input {
    width: 100%;
    height: 60px;
    font-size: 18px;
  }
  .socialmidia {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img {
      height: 19.47px;
      
    }
  }
  .input-div {
    height: 60px;
    background: #ffffff;
    border-radius: 25px 25px 25px 25px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 2rem;
    .join {
      width: 119px;
      height: 60px;
      background: #ededed;
      border-radius: 0px 25px 25px 0px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 65px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #343c5b;
      justify-content: center;
    }
  }
  .footer-airwallet {
    .h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 35px;
      /* or 233% */

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #ffffff;
    }
    h5 {
      display: none;
    }
    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 35px;
      width: auto;
      display: flex;
      align-items: start;
      color: #ffffff;
      margin-bottom: 1rem;
    }
    .right-footer {
      order: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 3rem;
    }
    .left-footer {
      width: 100%;
      order: 2;
    }
    .innerfooter {
      padding-top: 3rem;
      padding-bottom: 3rem;
      border-bottom: 1px solid #ffffff;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
  }
}

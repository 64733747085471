$burger-color: #000;
$burger-color-active: #000;
$ham-width: 22px;
$ham-height: 2px;
.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 47px;
  border-radius: 50%;
  &.is-active {
    .hamburger-inner {
      background-color: transparent;
      &::before,
      &::after {
        background-color: $burger-color;
      }
    }
  }
}

.hamburger-box {
  width: $ham-width;
  height: $ham-width;
  display: inline-block;
  position: relative;
  margin: auto;
}

.hamburger-inner {
  display: block;
  top: 50%;
  width: $ham-width;
  height: $ham-height;
  background-color: transparent;
  border-radius: $ham-height;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;

  &::before,
  &::after {
    width: $ham-width;
    height: $ham-height;
    background-color: $burger-color;
    border-radius: $ham-height;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: -7px;
  }

  &::after {
    bottom: -4px;
  }
}

/*
   * Squeeze
   */

.hamburger--squeeze {
  .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    background-color: $burger-color-active;

    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
      background-color: $burger-color-active;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      background-color: $burger-color-active;
    }
  }
}

.span1 {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}

.img1 {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.span2 {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: 180px;
  height: 55px;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.img3 {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.footer-area {
  background: #191c20;
  padding-top: 100px;
  z-index: 1;
  position: relative;
}
.pt-8 {
  padding-top: 2rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}



.md\:flex-row {
  flex-direction: row !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}
.flex-col {
}
.flex {
  display: flex !important;
}

.lg\:w-1\/4 {
  width: 25% !important;
}

.md\:px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.md\:block {
  display: block !important;
}

.cursor-pointer {
  cursor: pointer;
}
.flex-col {
  flex-direction: column !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.flex {
  display: flex !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.mt-0 {
  margin-top: 0 !important;
}

.w-full {
  width: 100% !important;
}

.h-40 {
  height: 10rem !important;
}
.relative {
  position: relative !important;
}

.bg-center {
  background-position: 50% !important;
}

.bg-cover {
  background-size: cover !important;
}
.rounded-xl {
  border-radius: 0.75rem !important;
}

.md\:mt-3 {
  margin-top: 0.75rem !important;
}

.md\:ml-0 {
  margin-left: 0 !important;
}
.justify-center {
  justify-content: center !important;
}
.flex-col {
  flex-direction: column !important;
}
.flex {
  display: flex !important;
}

.md\:text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.text-white {
  color: rgba(255, 255, 255, 1) !important;
}
.font-bold {
  font-weight: 700 !important;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.items-center {
  align-items: center !important;
}

.flex {
  display: flex !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.text-neutral-50 {
  color: rgba(249, 250, 251, 1) !important;
}

.text-xxs {
  font-size: 0.6rem !important;
}

.md\:flex-row {
  flex-direction: row !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}
.flex-col {
  flex-direction: column !important;
}
.flex {
  display: flex !important;
}
.lg\:w-1\/4 {
  width: 25% !important;
}

.md\:px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.md\:flex {
  display: flex !important;
}
.justify-between {
  justify-content: space-between !important;
}
.place-items-center {
  align-items: center !important;
  justify-items: center !important;
  place-items: center !important;
}
.md\:flex {
  display: flex !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.footer-text {
  color: #fff;
  margin: 10px 0;
  text-align: left;
  font-size: 16px;
}
a {
  text-decoration: none;
}
.font-bold {
  font-weight: 700 !important;
}

.bg-white {
  background-color: rgba(255, 255, 255, 1) !important;
}
.bg-gray-200,
.bg-white {
  --tw-bg-opacity: 1 !important;
}
.rounded-full {
  border-radius: 9999px !important;
}
.h-\[1px\] {
  height: 1px !important;
}
.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.grid {
  display: grid !important;
}

.lg\:gap-2 {
  gap: 0.5rem !important;
}

.lg\:grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}
.footer-internal-linkings ul {
  text-align: left;
  list-style: none;
}
.pl-0 {
  padding-left: 0 !important;
}
ul {
  padding-left: 0;
}
.text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}
.footer-internal-links {
  color: hsla(0, 0%, 100%, 0.565) !important;
}

.w-full {
  width: 100% !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}
.justify-end {
  justify-content: flex-end !important;
}

.flex {
  display: flex !important;
}

.justify-between {
  justify-content: space-between !important;
}
.items-center {
  align-items: center !important;
}
.w-max {
  width: -moz-max-content !important;
  width: max-content !important;
}
.flex {
  display: flex !important;
}
.-mb-3 {
  margin-bottom: -0.75rem !important;
}

.Tooltip-Wrapper {
  display: inline-block;
}

.w-full {
  width: 100% !important;
}

.text-white {
  color: rgba(255, 255, 255, 1) !important;
}

.font-semibold {
  font-weight: 600 !important;
}
.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.text-lg,
.text-xl {
  line-height: 1.75rem !important;
}

.inline-block {
  display: inline-block !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid {
  display: grid !important;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}
.text-\[\#ffffff90\] {
  color: hsla(0, 0%, 100%, 0.565) !important;
}

.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
.col-span-1 {
  grid-column: span 1 / span 1 !important;
}

.media {
  display: flex;
  flex-direction: column;
  height: 21rem;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  h1 {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    color: rgba(83, 89, 234, 1) !important;
    font-weight: 600 !important;
    margin-bottom: 6rem;
    text-align: center;
  }
  img {
    height: 52px;
  }
  .sectionimag {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.seccondfooter {
  margin-bottom: 2.5rem;
  margin-top: 3rem;

  .caontinercutom {
    background-color: rgba(243, 246, 254, 1) !important;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
    padding-left: 30px;
    border-radius: 20px;
    position: relative;
  }
  img {
    position: absolute;
    top: -49px;
    right: 126px;
    height: 23rem;
  }
  .left {
    h1 {
      font-size: 1.875rem !important;
      line-height: 2.25rem !important;
      margin-top: 2rem !important;
      color: black;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      color: rgba(107, 114, 128, 1) !important;
      font-size: 14px;
      color: rgb(107, 114, 128) !important;
      width: 38rem;
      margin-top: 13px;
      line-height: 30px;
    }
    label {
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
      font-weight: 600 !important;
      background-color: rgba(83, 89, 234, 1) !important;
      color: white;
      text-align: center;
      padding-top: 10px;
      border-radius: 10px;
      padding-bottom: 10px;
      width: 11rem;
      margin-top: 2rem;
    }
  }
}

// asd

.spanning {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.bg-gray-900 {
  background-color: rgba(17, 24, 39, 1) !important;
}
.lg\:my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.w-full {
  width: 100% !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.md\:grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.md\:grid {
  display: grid !important;
}
.lg\:mt-16 {
  margin-top: 4rem !important;
}
.lg\:text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}
.text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}
.font-bold {
  font-weight: 700 !important;
}

.text-neutral-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.md\:text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.md\:mt-8 {
  margin-top: 2rem !important;
}
.lg\:mt-0 {
  margin-top: 0 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.rounded-xl {
  border-radius: 0.75rem !important;
}
.shrink-0 {
  flex-shrink: 0 !important;
}

.md\:block {
  display: block !important;
}
.text-lg,
.text-xl {
  line-height: 1.75rem !important;
}
.text-xl {
  font-size: 1.25rem !important;
}
.w-1\/2 {
  width: 50% !important;
}
.hidden {
  display: none !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.text-center {
  text-align: center !important;
}
.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.bg-primary {
  background-color: rgba(83, 89, 234, var(--tw-bg-opacity)) !important;
}
.bg-primary,
.bg-purple-200 {
  --tw-bg-opacity: 1 !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.justify-center {
  justify-content: center !important;
}
.items-center {
  align-items: center !important;
}
.w-full {
  width: 100% !important;
}
.h-20 {
  height: 5rem !important;
}
.flex {
  display: flex !important;
}
.lg\:h-96 {
  height: 24rem !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

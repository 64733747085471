@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 999999;
  width: 100%;
  
  img {
    height: 30px;
  }
  .buttonWwrap {
    display: flex;
    align-items: center;
    margin-left: auto;
    background: transparent;
    height: 55px;
    border-radius: 27px;
    padding: 4px;
    transition: all 400ms ease;
    position: relative;
    .buttonText {
      font-size: 15px;
      padding: 10px;
      color: white;
      display: none;
    }
    .menuBg {
      height: 44px;
      width: 44px;
      border-radius: 22px;
      right: 65px;
      top: 18px;
      position: absolute;
      background: black;
      opacity: 0.9;
    }
    .menu {
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .menuItem {
        z-index: 2;
        font-size: 40px;
        font-weight: 600;
        margin: 20px 0;
        color: $primary-color;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: transparent;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $primary-color;
          color: transparent;
          text-decoration: none;
        }
      }
    }
    :global {
      .hamburger {
        z-index: 2;
        box-shadow: 0 20px 20px 0 rgb(0 0 0 / 5%), 0 0 20px 0 rgb(0 0 0 / 8%);
        transition: all 400ms ease;
      }
    }
    &:hover {
      background: #002b3880;
      .buttonText {
        display: flex;
      }
      :global {
        .hamburger {
          transform: rotate(-90deg);
        }
      }
    }
  }
  @include md {
    padding: 0 40px;
  }
}

.right-side-dg {
  width: 50%;
  height: 540px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .mobile-image {
    display: none;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 65px;
    /* or 118% */
    width: 657px;
    display: flex;
    align-items: center;

    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 45px;
    width: 657px;
    color: #343c5b;
    margin-top: 1.3rem;
    margin-bottom: 2.5rem;
  }
  .labelsection-landing {
    display: flex;
    align-items: center;
    .login {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #ffffff;
      background: #81cbd7;
      opacity: 0.9;
      border-radius: 10px;
      width: 196px;
      height: 62px;
      margin-right: 15px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }

    .started {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #ffffff;
      background: #3d4394;
      opacity: 0.9;
      border-radius: 10px;
      width: 229px;
      height: 62px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.landingPageDg {
  display: flex;
  align-items: center;
  padding-top: 75px;
  height: auto;
  height: calc(100vh - 73px);
}

.left-side-dg {
  width: 50%;
  height: 87%;
  display: flex;
}

.footer-dg {
  background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
  opacity: 0.9;
  height: 64px;
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  left: 0;
  z-index: 9;
  .inner-dg {
    max-width: 1180px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  h4 {
    display: none;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.container {
  max-width: 1180px;
}

.bottomBanner {
  height: 73px;
  width: 100%;

  bottom: 0;
  background: linear-gradient(380deg, #81cbd7 0%, #3d4394 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 999;
  .startfree {
    display: none;
  }
  .innerbanner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .text-area {
    width: 29.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .image-section {
    display: flex;
    align-items: center;
    position: relative;
    -webkit-animation: linear infinite;
    -webkit-animation-name: run;
    animation: linear infinite;
    -webkit-animation-duration: 1s;
    animation-name: run;
    animation-duration: 1s;

    img {
      height: 16px;
      width: 16px;
    }
  }

  @keyframes run {
    0% {
      left: 0;
    }

    50% {
      left: 10px;
    }

    100% {
      left: 0;
    }
  }

  .text-area {
    h6 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #ffffff;
      margin-bottom: 0;
    }
  }

  .store {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 19.5rem;

    .line-affilate {
      width: 1px;
      height: 40px;
      background-color: white;
    }

    .paystore {
      width: 123px;
      height: 31.27px;
      transition: all 0.6s ease-in;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition: all 0.6s ease-in;
      }
    }
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1569.9px) {
  .container {
    max-width: 1280px;
  }
}
@media only screen and (min-width: 1570px) {
  .container {
    max-width: 1380px;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1380px;
  }

  .footer-dg {
    .inner-dg {
      max-width: 1380px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .landingPageDg {
    height: calc(100vh);
  }
}
@media only screen and (max-width: 1000px) {
  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1;
    transition-property: auto !important;
  }

  [data-aos="fade-left"] {
    transform: translate3d(0, 0, 0) !important;
  }
  [data-aos^="fade"][data-aos^="fade"].aos-animate {
    opacity: 1;
    transform: translateZ(0) !important;
  }

  body[data-aos-easing="ease"] [data-aos] {
    transition-timing-function: linear !important;
  }
  [data-aos][data-aos][data-aos-duration="1000"] {
    transition-duration: 0s !important;
  }
  .bottomBanner {
    height: 79px;
    padding-left: 7%;
    padding-right: 7%;
    background: white;
    .startfree {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
      background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
      opacity: 0.9;
      border-radius: 10px;
      height: 67px;
      width: 100%;

      justify-content: center;
    }
    .store {
      height: 100%;
      display: none;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .text-area {
      display: none;
    }
  }
  .left-side-dg {
    display: none;
  }
  .landingPageDg {
    display: block;
    padding-top: 53px;
    height: calc(100vh - 97px);
  }
  .right-side-dg {
    width: 50%;
    height: auto;
    padding-top: 2rem;
  }
  .footer-dg {
    padding-left: 7%;
    padding-right: 7%;
    h2 {
      display: none;
    }
    .inner-dg {
      justify-content: center;
    }
    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 0;

      color: #ffffff;
    }
    img {
      display: none;
    }
  }
  .bottomBanner {
    display: none;
  }
  .right-side-dg {
    width: 100%;
    height: 100%;
    padding-bottom: 0px;
    .mobile-image {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 49%;
        cursor: pointer;
      }
    }
    .labelsection-landing {
      display: none;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .login {
        font-weight: 800;
        font-size: 17px;
        line-height: 21px;
        width: 48%;
        height: 43px;
        margin-right: 0;
        &:hover {
          transform: scale(1.1);
        }
      }

      .started {
        width: 48%;
        font-weight: 800;
        height: 43px;
        font-size: 17px;
        line-height: 21px;
      }
    }
    h1 {
      font-size: 35px;
      line-height: 50px;
      width: 100%;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 30px;
      width: 100%;
      margin-top: 0rem;
      margin-bottom: 1.2rem;
    }
  }
}

// @media (min-width: 1200px) {
//   .container {
//     max-width: 1180px !important;
//   }
// }
// @media (min-width: 1370px) {
//   .container {
//     width: 100% !important;
//     max-width: 1350px !important;
//   }
// }

@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.landingPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .mainCarousel {
    z-index: 0;
    height: 0;
    flex: 1;
    position: relative;
    .content {
      width: 100%;
      height: 100%;
      transition: all 0.4s ease;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }
  }
}

.nav-menu {
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  // padding-left: 140px;
  // padding-right: 140px;
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
  }
  .leftside {
    .buger{
      display: none;
    }
    img {
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .right-side {
    display: flex;
    align-items: center;
  }
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: #010101;

    margin-right: 47px;
    margin-bottom: 0;
    color: #343C5B;
    &:hover {
      transform: scale(1.1);
    }
  }
  .login {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
    opacity: 0.9;
    justify-content: center;
    border-radius: 10px;
    width: 147px;
    height: 44px;
    color: #ffffff;
    &:hover {
      transform: scale(1.1);
    }
  }
  .register {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 116px;
    height: 39px;
    margin-left: 10px;
    justify-content: center;
    background: #4b5bb2;
    border: 0.5px solid #4b5bb2;
    border-radius: 15px;
    color: #ffffff;
    margin-bottom: 0;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.right-side-modile {
  display: none;
}
@media only screen and (max-width: 756px) {
 
  .logo-dg {
    padding-left: 7%;
    height: 31px;
  }
  .right-side-modile {
    position: absolute;
    display: block;
    left: 0;
    height: 100vh;
    top: 0;
    padding-left: 7%;
    background-color: white;
    width: 100%;
    z-index: 9999999;
    padding-top: 88px;
    .cros {
      position: absolute;
      left: 7%;
      top: 25px;
    }
    .login {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;
      background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
      opacity: 0.9;
      justify-content: center;
      border-radius: 10px;
      width: 147px;
      height: 44px;
      color: #ffffff;
      &:hover {
        transform: scale(1.1);
      }
    }
    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      color: #343C5B;
      margin-bottom: 2rem;

      color: #343C5B;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .container {
    padding-left: 7%;
    padding-right: 7%;
  }
  .right-side {
    display: none;
  }
  .nav-menu {
    .leftside {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .buger {
        cursor: pointer;
        position: absolute;
        left: 0;
        padding-left: 7%;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

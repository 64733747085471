.SectionTwo {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  // border-bottom: 0.5px solid #e3e3e3;{
  padding-top: 74px;
  .left-side {
    padding-right: 13px;
    width: 60%;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 65px;
    /* or 186% */

    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #343c5b;
  }
  h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 65px;
    /* or 125% */
    margin-bottom: 3rem;
    display: flex;
    align-items: center;

    background: linear-gradient(180deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 38px;
    /* or 190% */
    width: 615px;
    align-items: center;

    color: #343c5b;
    span {
      color: #81cbd7;
      cursor: pointer;
      font-weight: 600;
    }
  }
  .zxcz {
    display: flex;
    align-items: center;
  }
  .RightSideAirwallate {
    position: relative;
    background-image: url("../../static/images/second.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    right: 0;
    top: 0;
    height: 80vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(8);
    border-radius: 25px;
  }
  .background-tint {
    background-color: #15172acf;

    background-blend-mode: multiply;
  }
  label {
    margin-top: 4rem;
    background: #3d4394;
    opacity: 0.9;
    color: white;
    border-radius: 10px;
    width: 229px;
    height: 62px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 0;
    &:hover {
      transform: scale(1.1);
    }
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 85px;

    text-align: center;
    letter-spacing: 0.06em;

    color: #ffffff;
  }
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;

    text-align: center;
    letter-spacing: 0.05em;

    color: #ffffff;
  }
  .sectionbottombanner {
    background: #81cbd7;
    border: 1px solid #81cbd7;
    height: 70px;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    text-align: center;
    border-radius: 0px 0px 15px 15px;
    color: #ffffff;
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}
.secondmobilecard {
  display: none;
}
@media (max-width: 900px) {
  .background-tint-molblie {
    background-color: rgba(61, 67, 148, 0.7);
    background-blend-mode: multiply;
  }
  .secondmobilecard {
    height: 229px;
    background-image: url("../../static/images/second.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    margin-bottom: 2rem;
    a .sectionbottombanner {
      display: none;
    }
    .name {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 35px;
      line-height: 43px;

      text-align: center;
      letter-spacing: 0.06em;

      color: #ffffff;
    }
    .date {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin-top: 10px;
      text-align: center;
      letter-spacing: 0.05em;

      color: #ffffff;
    }
    .reg {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 800;
      font-size: 17px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 1.5rem;
      color: #ffffff;
      background: #81cbd7;
      border-radius: 15px;
      width: 207px;
      height: 52px;
      justify-content: center;
    }
  }
  .SectionTwo {
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .left-side {
      width: 100%;
      padding-right: 0;
    }
    label {
      display: none;
    }
    P {
      font-size: 24px;
      line-height: 41px;
      justify-content: center;
      text-align: center;
      margin-bottom: 1rem;
    }
    h1 {
      width: 100%;
      justify-content: center;
      text-align: center;
    }
    h4 {
      line-height: 57px;
      justify-content: center;
      text-align: center;
    }
    .RightSideAirwallate {
      display: none;
    }
  }
}

.mainproduct {
  height: 100vh;
  background: linear-gradient(270deg, #81cbd7 0%, #3d4394 100%);
  .innerMainproduct {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: space-between;
    height: calc(100vh - 128px);
    padding-top: 6rem;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    /* or 159% */
    margin-bottom: 0;
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
    width: 570px;
    color: #ffffff;
  }
}
.leftsideproduct {
  width: 535px;
  height: 346px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3.3rem;
  .cutomh1{
    display: none;
  }
  .mobilelabel{
    display: none;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 65px;
    /* or 217% */

    display: flex;
    align-items: center;

    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  input {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border: 0.5px solid #e7e7e7;
    color: #343c5b;
    width: 450px;
    height: 75px;
    margin-top: 1rem;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    border-radius: 15px;

    opacity: 0.25;
  }
  label {
    margin-top: 3rem;
    background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
    opacity: 0.9;
    border-radius: 10px;
    width: 183px;
    height: 52px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;

    justify-content: center;
  }
}

.imageProduct {
  display: flex;
  align-items: center;
  img{
    margin-right: 10px;
    height: 52px;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 67px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #ffffff;
  }
}
.Innercardproduct {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}
.cardproduct {
  background-color: white;
  .cardProduct {
    height: 247px;
    background: #ffffff;
    border: 0.5px solid #ebebeb;
    border-radius: 15px;
    width: 32%;
    padding: 32px;
    img {
      margin-top: 2rem;
      height: 41px;
    }
  }
  .owner {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    align-items: center;

    color: #343c5b;
  }
  .dis-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 28px;
    /* or 233% */

    display: flex;
    align-items: center;

    color: #343c5b;
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 1750px) {
  .mainproduct {
    height: 100vh;
    background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
    .innerMainproduct {
      display: flex;
      align-items: center;
      height: 100vh;
      justify-content: space-between;
      height: calc(100vh - 328px);
    }
  }
}

@media only screen and (max-width: 1000px) {
  .mainproduct {
    height: 100vh;
    background: white;
    .innerMainproduct {
      display: block;
      align-items: center;
      height: 100vh;
      justify-content: space-between;
      height: auto;
      padding-top: 6rem;
    }
  }
  .imageProduct h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 50px;
    /* or 91% */

    display: flex;
    align-items: center;

    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 0;
  }
  .mainproduct p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 0;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    width: auto;

    color: #343c5b;
  }
  .labelSectionproduct {
    display: flex;
    align-items: center;
  }
  .leftsideproduct input {
    display: none;
  }
  .mobilelabel {
    background: white !important;
    color: #343c5b !important;
    display: flex !important;
  }
  .leftsideproduct {
    width: 100%;
    height: 248px;
    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    border: 0.5px solid #e7e7e7;
    border-radius: 15px;
    display: flex;
    left: 0;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    position: absolute;
    width: 100%;
    margin-top: 3rem;
    h1 {
      display: none;
    }
    .cutomh1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      /* or 133% */
      display: block;

      color: #ffffff;
    }
  }
  .Innercardproduct {
    display: block;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 18rem;
}
.cardproduct .cardProduct {
  height: 247px;
  background: #ffffff;
  border: 0.5px solid #ebebeb;
  border-radius: 15px;
  width: 100%;
  padding: 32px;margin-bottom: 1rem;
}
}

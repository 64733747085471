.dropcontainer {
  /* padding-top: 70px; */

  /* height: 100%; */
  background-color: white;
  z-index: 99;
  padding-top: 70px;
  padding-bottom: 15px;
  width: 100vw;
  position: absolute;
  padding-top: 6rem;
  height: 84vh;
  background-color: white;
  z-index: 9999;
  width: 100vw;
  position: fixed;
  padding-top: 0;
  /* height: 100%; */
  background-color: white;
  z-index: 99;
  top: 96px;
  overflow: scroll;
  max-width: 1498px;
  border: 0.5px solid #e5e5e5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 15px 15px;
  margin: auto;
  display: flex;
  /* width: 100%; */
  width: 94%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999999;
  top: 95px;
  padding-bottom: 2.5rem;
  border-top: 0px;
}

.alldropdowns {
  /* height: calc(100vh - 110px); */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  transition: all ease 0.3s;
  background-color: white;
}

.eachdropdown {
  border: none;
  outline: none;
  color: #000;
  width: 33.3333333333333333%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.eachdropdown:hover {
  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
    0 3px 7px -3px rgba(0, 0, 0, 0.3);
  /* border-radius: 25px; */
}

.droptitle {
  margin-top: 14px;
  font-weight: 600;
  font-size: 25px;
  line-height: 45px;
  color: #1f304f;
}

.droptext {
  margin-top: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 45px;
  color: #1f304f;
}

.divide-text {
  width: 100%;
  height: 76px;
  font-weight: 500;
  font-size: 20px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  padding-left: 0vw;
  color: #343c5b;
}
@media only screen and (min-width: 0px) and (max-width: 900px) {
  .eachdropdown {
    width: 100vw;
    align-items: center;
    text-align: center;
    padding-left: 0px;
  }
}

.Section-Three {
  padding-bottom: 2.5rem;
  .top-sectionthree {
    padding-top: 5rem;
    padding-bottom: 5rem;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 75px;
    /* or 125% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 65px;
    /* or 232% */
    margin-bottom: 0;
    text-align: center;

    color: #343c5b;
  }
  .border {
    width: 443.01px;
    height: 1px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #e5e5e5;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 42px;
    /* or 191% */
    width: 630px;
    margin-bottom: 0;
    text-align: center;

    color: #343c5b;
  }
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
  }

  .bottom-sectionThree {
    display: flex;
    align-items: center;
    height: 300px;
    justify-content: space-between;
  }
  .background-tint {
    background-color: rgba(0, 0, 0, 0.7);
    background-blend-mode: multiply;
  }
  .card-sectionthree {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32.33%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 244.07px;
    flex-direction: column;
    border-radius: 15px;
    &:hover {
      position: relative;
      top: 3px;
      transition: all ease 0.6s;
      cursor: pointer;
    }
  }
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    text-align: center;

    color: #ffffff;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .Section-Three .top-sectionthree {
    padding-top: 5rem;
    padding-bottom: 5rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media (max-width: 900px) {
  .Section-Three {
    // height: 100vh;
    p {
      font-weight: 300;
      font-size: 17px;
      line-height: 28px;

      margin-bottom: 0;
      text-align: center;
      color: #343c5b;
      padding-top: 2rem;
      width: 100%;
    }
    h1 {
      font-size: 46px;
      text-align: center;
      line-height: 54px;
      // line-height: 40px;
    }
    .border {
      display: none;
    }
    h2 {
      font-size: 19px;
      line-height: 26px;
      text-align: center;
    }
    .bottom-sectionThree {
      display: block;
      height: auto;
    }
    .card-sectionthree {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 1rem;
    }
    .top-sectionthree {
      height: auto;
      padding-bottom: 4rem;
      align-items: center;
      display: flex;
      padding-top: 3rem;
    }
  }
}

.priceingtabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1.3rem;
  .round1 {
    width: 1rem;
    height: 1rem;
    border-radius: 50px;
    background-color: #e38627;
    margin-right: 10px;
    margin-top: 2px;
  }
  .round2 {
    width: 1rem;
    height: 1rem;
    border-radius: 50px;
    background-color: #c13c37;
    margin-right: 10px;
    margin-top: 2px;
  }
  .round3 {
    width: 1rem;
    height: 1rem;
    border-radius: 50px;
    background-color: #6a2135;
    margin-right: 10px;
    margin-top: 2px;
  }
  .round4 {
    width: 1rem;
    height: 1rem;
    border-radius: 50px;
    background-color: #624562;
    margin-right: 10px;
    margin-top: 2px;
  }
  .round5 {
    width: 1rem;
    height: 1rem;
    border-radius: 50px;
    background-color: #908876;
    margin-right: 10px;
    margin-top: 2px;
  }
}
.PriceDash {
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 1rem;
}

.piechart {
  width: 50%;
  display: flex;
  align-items: center;
  height: 375px;
  justify-content: center;
  svg {
    width: 80%;
  }
}

.cWgBMk {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 60px;
}
.dOBTgm {
  color: rgb(13, 12, 67);
  margin-top: 0px;
}

.llAHHx {
  font-weight: 600  !important;
}
.llAHHx {
  font: 2.4rem / 110% "Sofia Pro", sans-serif;
  text-align: center;
}

.hIHHmL {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 16px;
  max-width: 1000px;
}
.hIHHmL {
  gap: 30px;
}
.eyOdzP {
  color: rgb(0, 178, 200);
  text-decoration: none;
  transition: color 0.2s ease 0s;
}

.ifBXzi {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  height: 74px;
  width: 160px;
  box-shadow: rgb(5 85 95 / 4%) 0px 8px 32px, rgb(5 85 95 / 2%) 0px 3px 24px;
  border-radius: 22px;
  background: rgb(255, 255, 255);
  padding: 20px;
}
.ifBXzi {
  height: 90px;
  width: 200px;
  padding: 28px;
}
.gnMwmS {
  position: relative;
  width: 100%;
  height: 100%;
  img {
    height: 2rem;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.jJrqEA {
  flex-direction: row;
}

.jJrqEA {
  display: flex;

  margin: 100px 0px;
}

.krAzUw {
  max-width: 1320px;
}

.krAzUw {
  padding-left: 24px;
  padding-right: 24px;
}

.fCAobH {
  flex-basis: 50%;
  img {
    width: 100%;
  }
}

.fCAobH {
  margin: 0px auto 24px;
}
.hBlCyM {
  text-align: left;
  flex-basis: 50%;
  margin-left: 60px;
}
.jAxhdP {
  font: 2.5rem / 110% "Sofia Pro", sans-serif;
  margin-top: 24px;
  margin-bottom: 24px;
  color: rgb(13, 12, 67);
  text-align: inherit;
}
.gMkjTe {
  font: 1.4rem / 145% "Sofia Pro", sans-serif;
  color: rgba(13, 12, 67, 0.55);
  text-align: inherit;
}
.bRCnnv {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}
.fEFqKn {
  margin-right: 12px;
  transform: scale(1) rotate(0deg);
  transition: transform 0.15s ease-in-out 0s;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: rgb(5 85 95 / 0%) 0px 0px 0px, rgb(5 85 95 / 0%) 0px 0px 0px;
}

.kBovqd {
  width: 24px;
  height: 24px;
  color: rgb(0, 178, 200);
  fill: rgb(0, 178, 200);
}
.dQtLSu {
  display: inline-block;
  position: relative;
  margin-left: 4px;
  white-space: nowrap;
  color: rgb(13, 12, 67);
  font: 1rem / 145% "Sofia Pro", sans-serif;
}
.dQtLSu::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 60px;
  max-width: 100%;
  height: 2px;
  background-color: rgba(13, 12, 67, 0.2);
  transition: width 0.15s ease-in-out 0s;
}


.hBlCyM {
  text-align: left;
  flex-basis: 50%;
  margin-left: 0;
}
.jAxhdP {
  font: 2rem/110% "Sofia Pro", sans-serif;
  margin-top: 14px;
  margin-bottom: 24px;
  color: rgb(13, 12, 67);
  text-align: inherit;
  line-height: 34px;
}
.fCAobH {
  margin: 0px auto 24px;
  display: none;
}
.sc-dmRaPn{
  text-align: center;
}
.hBlCyM {
  text-align: left;
  flex-basis: 100%;
  margin-left: 0;
}
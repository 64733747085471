.sectionfour {
  padding-top: 5rem;
  padding-bottom: 2rem;
  .broker{
    display: none;
  }
  .borderBroker{
    display: none;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 65px;
    /* or 125% */
    margin-bottom: 0px;

    text-align: center;

    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    border-radius: 25px;
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 65px;
    /* or 232% */

    text-align: center;

    color: #343c5b;
  }
  .box-four {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5rem;
  }

  .fourCardcutom {
    border-radius: 25px;
    border: 1px solid transparent;
    background: rgba(182, 181, 181, 0.06);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    height: 512px;
    padding: 13px;
    border-radius: 10px;
    padding: 36px;
    width: 48%;
    display: flex;
    display: flex;
    align-items: flex-start;
    // justify-content: space-between;
    cursor: pointer;
    flex-direction: column;
    &:hover {
      position: relative;
      top: -2px;
      border: 1px solid transparent;
      // border-image: linear-gradient(45deg, #81cbd7, #3d4394);
      background: linear-gradient(to right, white, white),
        linear-gradient(0deg, #3d4394 30%, #81cbd7 67%);
      background-position: 0 0, 100% 0;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
    }
    .deatil {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      /* or 167% */

      display: flex;
      align-items: start;
      margin-top: 1.5rem;
      color: #343c5b;
      img {
        margin-top: 5px;
        margin-right: 15px;
      }
    }
    .h2 {
      margin-top: 0px !important;
      font-size: 40px !important;
      line-height: 53px !important;
      text-align: start !important;
    }
    .h5 {
      font-size: 21px !important;
      line-height: 38px !important;
      text-align: start !important;
      margin-top: 1rem !important;
      height: auto !important;
    }
    label {
      background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
      opacity: 0.9;
      border-radius: 10px;
      width: 240px;
      height: 60px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin-bottom: 0;
      margin-top: 3rem;
      color: #ffffff;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .fourCard {
    border-radius: 25px;
    border: 1px solid transparent;
    // border-image: linear-gradient(45deg, #81cbd7, #3d4394);
    background: linear-gradient(to right, white, white),
      linear-gradient(0deg, #3d4394 30%, #81cbd7 67%);
    background-position: 0 0, 100% 0;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    height: 569px;
    padding: 13px;
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
    width: 24.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 45px;
    /* or 129% */

    text-align: center;

    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    margin-top: 2rem;
    -webkit-text-fill-color: transparent;
  }
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    margin-top: 2rem;
    /* or 167% */
    height: 15rem;

    text-align: center;

    color: #343c5b;
  }
}

@media (max-width: 900px) {
  .sectionfour {
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 55px;
      text-align: center;
    }
    .box-four {
      display: block;
      // overflow: scroll;
    }
    h2 {
      margin-top: 1rem;
    }
    h5 {
      margin-bottom: 0px;
      height: auto;
      margin-top: 1rem;
    }
    .p {
      text-align: center;
      font-size: 17px;
      line-height: 35px;
      margin-top: 1rem;
      display: none !important;
    }
    p {
      text-align: center;
      font-size: 17px;
      line-height: 35px;
      margin-top: 1rem;
     
    }
 .borderBroker{
  display: block;
width: 100%;
height: 1px;
background: #E5E5E5;
margin-bottom: 1rem;
margin-top: 1rem;
 }
    
    .broker {
      display: block;
      text-align: center;
      font-size: 17px;
      line-height: 35px;
      margin-top: 1rem;
      color: #343c5b;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
    }
    .fourCard {
      width: 100%;
      margin-right: 0rem;
      height: auto;
      margin-bottom: 1.5rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .fourCardcutom {
      border-radius: 25px;
      border: 1px solid #ddd;
      background: rgba(182, 181, 181, 0.06);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      height: auto;
      padding: 13px;
      border-radius: 10px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 2rem;
      padding-bottom: 2rem;
      width: 100%;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex-direction: column;
      margin-bottom: 1rem;
      .h2 {
        margin-top: 0px !important;
        font-size: 27px !important;
        line-height: 40px !important;
        text-align: center !important;
      }
      h5 {
        margin-bottom: 0px;
      }
      .h5 {
        font-size: 18px !important;
        line-height: 38px !important;
        text-align: center !important;
      }
    }
  }
}

.dtRnqr {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 100%;
}

.dtRnqr {
  padding-left: 24px;
  padding-right: 24px;
}

.bUbOKB {
  z-index: -1;
  overflow: hidden;
}

.ZupoF {
  z-index: -1;
  position: absolute;
  left: -21%;
  top: 11%;
}

.krAzUw {
  max-width: 1320px;
}

.krAzUw {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.Qauac {
  position: sticky;
  top: 150px;
}
.iFtgWr {
  margin: 24px auto;
  font: 1.8rem / 110% "Sofia Pro", sans-serif;
  color: rgb(0, 178, 200);
  text-align: center;
}

.hnxLCF {
  margin: 24px auto;
  font: 3.2rem / 110% "Sofia Pro", sans-serif;
  color: rgb(13, 12, 67);
  text-align: center;
}

.jSAoiw {
  position: relative;
  z-index: 1;
  list-style-type: none;
  margin: 40px 0px 0px;
  padding: 0px;
  background-color: white;
}

.jSAoiw {
  max-width: 700px;
  margin: 100px auto 0px;
}

.dkGBOY {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 24px;
  margin: 12px 0px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  box-shadow: rgb(5 85 95 / 4%) 0px 3px 32px, rgb(5 85 95 / 4%) 0px 11px 24px;
  border-radius: 25px;
  -webkit-box-align: center;
  align-items: center;
}

.dkGBOY {
  flex-direction: row;
  margin: 24px 0px;
  padding: 48px 32px;
}
.dkGBOY::before {
  content: "";
  position: absolute;
  right: 0px;
  height: 200px;
  width: 200px;
  background-image: url("https://www.cryptohopper.com/static/images/expert-tools/bg-image1.svg");
  background-repeat: no-repeat;
  background-position: 0px 0px;
  transform: translateY(-118px);
}
.kGBvcA {
  width: 120px;
  flex-shrink: 0;
  img {
    width: 100%;
  }
}
.exdsCQ {
  margin-left: 32px;
  text-align: left;
}
.gAmKkJ {
  font: 2rem / 110% "Sofia Pro", sans-serif;
}
.gMkjTe {
  font: 1rem / 145% "Sofia Pro", sans-serif;
  color: rgba(13, 12, 67, 0.55);
  text-align: inherit;
}
.bRCnnv {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

@media (max-width: 768px) {
  .hnxLCF {
    margin: 24px auto;
    font: 2.2rem/110% "Sofia Pro", sans-serif;
    color: rgb(13, 12, 67);
    text-align: center;
}
  .dkGBOY {
    flex-direction: column;
    padding: 11px 12px;
  }
  .kGBvcA {
    margin-bottom: 2rem;
  }
  .exdsCQ {
    margin-left: 12px;
    text-align: left;
  }
  .gAmKkJ {
    font: 1.5rem/110% "Sofia Pro", sans-serif;
  }
  .gMkjTe {
    font: 18px/145% "Sofia Pro", sans-serif;
    color: rgba(13, 12, 67, 0.55);
    text-align: inherit;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

@import "colors";
@import "mixin";
@import "hamburgers";

body {
  font-family: Montserrat !important;

  #root {
    * {
      font-family: Montserrat;
      outline: none;
      box-sizing: border-box;
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

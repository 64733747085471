.fini-full {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sub {
    width: 48%;
  }
}
.fincial{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
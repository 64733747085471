.OverView {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: white;
}
.avgREntal {
  color: rgba(156, 163, 175, 1) !important;

  font-weight: 500 !important;

  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.avgpercentage {
  color: rgba(17, 24, 39, 1);
  font-weight: 600 !important;

  font-size: 1rem !important;
  line-height: 1.5rem !important;
  margin-top: 0.25rem;
}
.boxshow {
  width: 33%;
}
.lineBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lineBoxnowrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #ddd;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 10px;
}
.boxavg {
  border-radius: 10px;
  border: 0.5px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 24%;
  align-items: center;
  height: 5rem;
  border-bottom: 5px solid #000;
}
.borderimag {
  width: calc(100% - 42px);
  margin-bottom: 7px;
  margin-left: 5px;
  margin-right: 5px;
}
.vistImage {
  width: 100%;
  border-radius: 10px;
  height: 178px;
  margin-top: 0rem;
  margin-bottom: 2rem;
}
.one {
  background-color: rgba(83, 89, 234, 1) !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 30px;
  font-size: 14px;
}
.imagecover {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.boxlease {
  border-radius: 10px;
  border: 0.5px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 32%;
  padding-left: 15px;
  margin-bottom: 1rem;
  height: 5rem;
}
.OverviewText {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  color: #000;
  margin-bottom: 1.5rem;
  font-weight: 600 !important;

  margin-bottom: 2rem;
}
.leftsideProps {
  width: 65%;
  background-color: white;
}

.Textdata {
  color: rgba(107, 114, 128, 1) !important;
  font-weight: 400;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.labelmodel {
  width: 48%;
  color: white;
  background-color: rgba(83, 89, 234, 1) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-radius: 10px;
  height: 3rem;
  font-size: 17px;
  font-weight: 600;
}

.callmodel {
  width: 48%;
  background-color: rgba(229, 231, 235, 1) !important;
  color: rgba(31, 41, 55, 1) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-radius: 10px;
  height: 3rem;
  font-size: 17px;
  font-weight: 600;
}

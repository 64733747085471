.innerlanding {
  display: flex;
  align-items: flex-start;
  margin-top: 120px;

  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    /* or 125% */

    display: flex;
    align-items: center;

    color: #1f4271;
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 35px;
    /* or 194% */
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
    display: flex;
    align-items: center;

    color: #292929;
  }

  .download {
    width: 222px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 30px;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.rightsidelanding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 52%;
  height: 555px;
}

.leftsidelanding {
  width: 48%;
  img {
    width: 67%;
  }
}

.labelplaystore {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 460px;
  margin-top: 1rem;
}

.bannercard {
  width: 30%;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 0px;

    color: #ffffff;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }
}

.bannerMain {
  background-color: #4b5bb2;
  height: 8rem;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  .sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.textscrollerSection {
  padding-bottom: 3rem !important;
  margin-top: 5rem;
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 50px;
    padding-bottom: 1rem;
    width: 16rem;
    margin-bottom: 6rem;
    color: #1f4271;
    border-bottom: 5px solid #4b5bb2;
  }
  .leftside {
    width: 40%;
    padding-right: 91px;
    .innsersideLeft {
      display: flex;
      align-items: flex-start;
    }
    .Lineaccount {
      width: 1px;
      height: 130px;
      background: #4b5bb2;
      opacity: 0.25;
      margin-top: 4px;
      margin-left: 15px;
    }
  }
  .rightsidestep {
    width: 60%;
    padding-left: 93px;
  }
  .numbers {
    width: 35px;
    height: 35px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 50px;
    color: #ffffff;
    justify-content: center;
    background: #4b5bb2;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    /* or 136% */
    color: #1f4271;
    margin-top: 4px;
    margin-bottom: 0;
    margin-left: 15px;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 35px;

    color: #292929;
    margin-bottom: 0;
  }
}

.scroller-container {
  display: flex;
  height: 153px;
  align-items: flex-start;
}

._tmp4k {
  position: absolute;
  display: flex;
  height: 153px !important;
  justify-content: center;
  align-items: flex-start !important;
}
._35nH5 {
  height: 153px !important;
}

.invest-card {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 25px;
  width: 32%;
  padding: 2rem;
  &:hover{
    position: relative;
    top: -2px;
  }
  .checkshow {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .showSectionImage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6rem;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 50px;

    color: #1f4271;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;

    color: #182b48;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    margin-left: 10px;
    margin-bottom: 0;
    color: #182b48;
  }
}

.investemt {
  background: rgba(237, 237, 237, 0.37);
  padding-top: 3rem;
  padding-bottom: 3rem;
  h6 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 50px;
    padding-bottom: 3rem;

    color: #1f4271;
    padding-bottom: 1rem;
    width: 23rem;
    margin-bottom: 5rem;

    border-bottom: 5px solid #1f4271;
  }
  .card-investement {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

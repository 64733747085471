.krAzUw {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 1140px;
}

.krAzUw {
  max-width: 1320px;
}

.krAzUw {
  padding-left: 24px;
  padding-right: 24px;
}

.kFuWTM {
  margin: 24px auto;
  font: 2.5rem / 110% "Sofia Pro", sans-serif;
  color: rgb(13, 12, 67);
  text-align: center;
  font-weight: 600;
}

.hgULrs {
  display: flex;
  flex-flow: column wrap;
  list-style-type: none;
  margin: 48px 0px 100px;
  padding: 0px;
}
.hgULrs {
  flex-direction: row;
}

.gVAmXE {
  display: flex;
  flex-direction: row;
}

.gVAmXE {
  margin-top: 0px;
}

.gVAmXE {
  margin-top: 12px;
}

.gVAmXE {
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  flex-basis: calc(33.3333%);
  padding: 0px 24px;
}

.fsQOGD {
  width: 100px;
  flex-shrink: 0;
  cursor: pointer;
  img {
    width: 100%;
  }
}

.fsQOGD {
  width: 200px;
}
.bzjhcY {
  padding: initial;
  text-align: center;
}
.jkBSEn {
  font: 1.8rem / 110% "Sofia Pro", sans-serif;
  color: rgb(13, 12, 67);
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: inherit;
  font-weight: 600;
}
.gMkjTe {
  font: 1.4rem / 145% "Sofia Pro", sans-serif;
  color: rgba(13, 12, 67, 0.55);
  text-align: inherit;
}
.back-img {
  background-image: url("../../static/images/screen1.jpg");
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .kFuWTM {
    font-size: 1.5rem;
  }
  .gVAmXE {
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
    padding: 0px 24px;
}
}

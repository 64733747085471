.eight {
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  .eightimage {
    position: absolute;

    position: absolute;
    transform: translate(6rem, -5rem);
    height: 116%;
    right: 0;
  }
  .innereight {
    background: linear-gradient(290deg, #81cbd7 0%, #3d4394 100%);
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 25px;
    padding: 45px;
    padding-top: 45px;
    padding-bottom: 55px;
    position: relative;
 
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 65px;
    /* or 108% */

    display: flex;
    align-items: center;

    color: #ffffff;
    width: 816px;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    /* or 133% */
    margin-top: 2rem;
    display: flex;
    align-items: center;

    color: #ffffff;
  }
  .label-eight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;
    width: 32rem;
    img {
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.eightmobile {
  display: none;
}
@media only screen and (max-width: 1000px) {
  .eight {
    display: none;
  }
  .eightmobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(270deg, #81cbd7 0%, #3d4394 100%);
    padding-top: 4rem;
    .eightinner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .bimg {
      width: 98%;
      position: relative;
      left: 20px;
    }
    .eightlabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .label {
        width: 48%;
        height: 62px;

        border: 1px solid #ffffff;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 31px;

      line-height: 40px;
      /* or 114% */

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #ffffff;
    }
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      /* or 200% */

      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
    }
  }
}

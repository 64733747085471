.topTabFiltering {
    background-color: white;
}
.texttab {
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  margin-bottom: 0.25rem !important;
}
.input {
  padding-left: 10px;
  border: 1px solid #ddd;
  color: #000;
  font-size: 14px;
  height: 2.5rem;
  width: 195px;
  font-weight: 500;
  border-radius: 5px;
  &::placeholder {
    color: #000;
  }
}

.inputDash {
  position: relative;
  width: 195px;
  svg {
    position: absolute;
    right: 8px;
    top: 11px;
  }
}
.clearAll {
  background-color: #acacac63;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 14px;
  height: 2.5rem;
  width: 195px;
  text-align: center;
  font-weight: 500;
  margin-top: 20px;

  border-radius: 5px;
}
.topTabFiltering {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.showText {
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  color: black;
  span {
    color: rgb(83, 89, 234);
  }
}

.tablistproperty {
  border-radius: 6px;
  background-color: white;
  margin-right: 15px;
  border-radius: 6px;
  background-color: white;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid #ddd;
  width: max-content;
  padding-top: 3px;
  padding-bottom: 3px;
}

.contnettab {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}


.preleasedText{
    color: #000;
    font-weight: 700;
    font-size: 1.5rem!important;
}
.tenSection {
  padding-top: 6rem;
  padding-top: 6rem;
  display: flex;
  align-items: center;

  padding-bottom: 6rem;
  .innerTenSection {
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 75px;

      text-align: center;

      background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 28px;
      line-height: 65px;
      /* or 232% */

      text-align: center;

      color: #343c5b;
    }
    .tencard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
    }
    .arrrowTen {
      height: 19px;
    }
    .logoTen {
      height: 116px;
      transition: all 0.3s ease-out;
    }
    .imageSectionTen {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 5rem;
    }
    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 17px;
      line-height: 32px;
      /* or 188% */
      height: 8rem;
      display: flex;
      align-items: center;

      color: #343c5b;
    }
    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 45px;
      /* or 237% */
      margin-top: 1rem;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;

      color: #343c5b;
    }
    .toptencard {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 27px;
      justify-content: center;
      border-bottom: 1px solid #e5e5e5;
      .topImage {
        height: 45.64px;
        &:hover {
          transform: translateY(-5px);
          // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }
      }
    }

    .cirlcebutton {
      height: 48px;
      background-color: red;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 100px;
      transition: all 0.3s ease-out;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .textten {
        color: #fff;
        display: inline-flex;
        font-size: 15px;
        font-weight: 700;
        margin-left: 9px;
        display: none;
      }
    }
    .innertencard {
      height: 551px;
      width: 32%;
      // opacity: 0.03;
      padding: 27px;
      border: 0.5px solid #ededed;
      border-radius: 25px;
      cursor: pointer;
      &:hover {
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),
          0 2px 4px 0 rgba(0, 0, 0, 0.1),
          inset 0 0 0 1px hsla(0, 0%, 100%, 0.05);

        .arrrowTen {
          height: 19px;
          margin-left: 10px;
        }
        .logoTen {
          transform: scale(1.1);
          transition: all 0.3s ease-out;
        }
        .cirlcebutton {
          cursor: pointer;
          transition: all 0.3s ease-out;
          height: 48px;
          background-color: red;
          display: flex;
          align-items: center;
          padding-left: 20px;
          padding-right: 30px;
          border-radius: 100px;
          width: auto;
          display: flex;
          .textten {
            display: block;
          }
        }

        // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
    }
  }
}

@media (min-width: 1650px) {
  .tenSection {
    height: 100vh;
  }
}

@media (max-width: 1000px) {
  .tenSection .innerTenSection .tencard {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 4rem;
   
  }
  .tenSection .innerTenSection .innertencard {
    height: 551px;
    margin-bottom: 2rem;
    width: 100%;
    padding: 27px;
    border: 0.5px solid #ededed;
    border-radius: 25px;
    cursor: pointer;
  }
  .tenSection .innerTenSection .toptencard .topImage {
    height: 30.64px;
  }
  .tenSection .innerTenSection .logoTen {
    height: 83px;
    transition: all 0.3s ease-out;
  }
  .tenSection .innerTenSection h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 1rem;
  }
  .tenSection .innerTenSection p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 31px;
  }
  tenSection .innerTenSection h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
  }
}

.mobilenav {
  height: 55px;
  border-bottom: 0.5px solid #f5f5f5;
  width: 100%;
  z-index: 999;
  position: fixed;
  display: flex;
  padding-left: 7%;
  padding-right: 7%;
  background-color: white;
  left: 0;
  align-items: center;
  .logo {
    height: 25.3px;
  }
}

.menu-icon {
  width: 20px;
  height: 14px;
  cursor: pointer;
  position: relative;
}

.menu-icon span {
  background-color: black;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.menu-icon span:first-child {
  top: 0;
}

.menu-icon span:nth-child(2) {
  top: 6px;
}

.menu-icon span:last-child {
  top: 12px;
}

// .menu-icon:hover span:nth-child(2) {
//   opacity: 0;
// }

// .menu-icon:hover span:first-child,
// .menu-icon:hover span:last-child {
//   top: 6px;
//   background-color: black;
// }

// .menu-icon:hover span:first-child {
//   transform: rotate(45deg);
// }

// .menu-icon:hover span:last-child {
//   transform: rotate(-45deg);
// }

.cross {
}
.cross {
  width: 20px;
  height: 14px;
  cursor: pointer;
  position: relative;
}

.cross span {
  background-color: black;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.cross span:nth-child(2) {
  opacity: 0;
}

.cross span:first-child,
.cross span:last-child {
  top: 6px;
  background-color: black;
}

.cross span:first-child {
  transform: rotate(45deg);
}

.cross span:last-child {
  transform: rotate(-45deg);
}

.right-side-nav {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
  label {
    margin-bottom: 0px;
    width: 105px;
    background: #81cbd7;
    opacity: 0.9;
    border-radius: 5px;
    height: 36px;
    margin-right: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
  }
}

.drawer-nav {
  height: 100vh;
  z-index: 99;
  background-color: white;
  width: 100%;
  overflow: scroll;
  position: fixed;
  transition: all 0.3s ease;

  top: 0;
  padding-top: 70px;
  .menu-drawer {
    padding-left: 7%;
    padding-right: 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;

      color: #343c5b;
      margin-bottom: 0;
    }
    .down {
      transition: all 0.3s ease;
    }
    .up {
      transition: all 0.3s ease;
    }
  }
}
.labelsection {
  padding-left: 7%;
  padding-right: 7%;
}
.Getstarted {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  justify-content: center;
  background: #3d4394;
  opacity: 0.9;
  border-radius: 10px;
  height: 67px;
  width: 100%;
  margin-bottom: 3rem;
}
.login-nav {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 25px;
  color: #ffffff;
  justify-content: center;
  background: #81cbd7;
  opacity: 0.9;
  border-radius: 10px;
  height: 67px;
  width: 100%;
}
.subdrawer {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 2rem;
  background: #f5f5f5;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    color: #343c5b;
  }

  .submenu-drawer {
    display: flex;
    padding-bottom: 2rem;
    align-items: center;
    .lo {
      height: 43.58px;
      margin-right: 15px;
    }
    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;

      color: #343c5b;

      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .mobilenav {
    display: none;
  }
}
